html, body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  min-height: 100vh;
  background: repeating-linear-gradient(45deg, #fbfbfb, #fbfbfb 10px, #fefefe 10px, #fefefe 20px);
}

a {
  color: inherit;
  text-decoration: none;
}
